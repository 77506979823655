import React, { Component } from 'react';

class Footer extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (

        <div>
        
                
        <footer className="pt_60 pb_60">
          <div className="container">
            <ul className="footer_pdf">
              <li>
                <a target="_blank" href="pdf/Lionspoint_Tagalog.pdf">
                  <img src="img/ppt.png" alt="" /><span>Lionspoint Tagalog PPT</span>
                </a>
              </li>
              <li>
                <a target="_blank" href="pdf/Lionspoint_Hindi.pdf">
                  <img src="img/ppt.png" alt="" /><span>Lionspoint Hindi PPT</span>
                </a>
              </li>
              <li><a target="_blank" href="pdf/Lionspoint_english.pdf">
                <img src="img/ppt.png" alt="" /><span>Lionspoint English PPT</span>
              </a>
            </li>
            <li>
              <a target="_blank" href="video/English-Presentation.mp4">
                <img src="img/video.png" alt="" /><span>English video presentation</span>
              </a>
            </li>
            <li>
              <a target="_blank" href="video/Hindi-Presentaion.mp4">
                <img src="img/video.png" alt="" /><span>Hindi video presentation</span>
              </a>
            </li>
          </ul>
          </div>
          <hr className="dark_hr" />
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <p>&copy; 2021 lionspoint All Rights Reserved. </p>
              </div>
            </div>
          </div>
        </footer>

      {/* go to tp */}
      <a href="javascript:" id="return-to-top"><i className="fa fa-arrow-up"></i></a>
      {/* go to tp */}

      {/* loader */}
      <div className="overlay">
        <div className="overlayDoor"></div>
          <div className="overlayContent">
              <img src="img/logo.png?dxc" alt="" />
              <p>Loading.....</p>
          </div>
      </div>
       {/* loader */}
     
    </div>

      );
    }
  }

  export default Footer;