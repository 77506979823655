import React, { Component } from 'react';
import Frame from "../../components/frame";
import Joinnow from "./joinnow";
import Form from "./form";
import Pools from "./pools";
import {withRouter,Link} from "react-router-dom";
import Lionsgate from "../../contracts/Lionsgate.json";
import getWeb3 from "../../getWeb3";

class Home extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        storageValue: 0,
        web3: null,
        accounts: null,
        contract: null,
        contractAddress: null,
        sponsor_address: (this.props.match.params.referral != 'undefined') ? this.props.match.params.referral : '',
        userInfo:{},
        poolDeposit:[0,0,0],
        poolIncome:[0,0,0],
        TotalBonus:0,
        totalWithdrawn:0,
        totalDeposits:0,
        referral_count:[0,0,0,0,0,0],
        networkMain:false,
        YourAddress: '-',
        YourAddressfull: null,
      }      
    }

    componentDidMount = async () => {
      try {
        // Get network provider and web3 instance.
        const web3 = await getWeb3();
  
        // Use web3 to get the user's accounts.
        setInterval(async()=>{
          const accounts = await web3.eth.getAccounts();
         
          if(this.state.accounts !== accounts[0]){
          // Get the contract instance.
          const networkId = await web3.eth.net.getId();
          //alert(networkId);
          if(networkId !== 56) throw "Please connect Mainnet"; 
          const deployedNetwork = Lionsgate.networks[networkId];
          const instance = new web3.eth.Contract(
            Lionsgate.abi,
            deployedNetwork && deployedNetwork.address,
          );   
          
          this.setState({ web3, accounts:accounts[0], contract: instance, contractAddress:deployedNetwork.address}, this.fetchData);
          }
       },1000);

      } catch (error) {
        // Catch any errors for any of the above operations.
        // alert(
        //   error
        // );
        this.setState({networkMain:false});
        console.error(error);
      }
    };

    fetchData = async() => {
      const { accounts, contract } = this.state;

      let YourAddress = accounts.slice(0, 10) + '.....' + accounts.slice(accounts.length-5, accounts.length);
      let YourAddressfull = accounts;
      let userInfo = await contract.methods.users(accounts).call();
      userInfo.amount = this.state.web3.utils.fromWei(userInfo.amount, 'ether');
      userInfo.referrerBonus = this.state.web3.utils.fromWei(userInfo.referrerBonus, 'ether');
      let poolDeposit = [];
      for(let i=0;i<3;i++){
        let tempVal = await contract.methods.pool_deposits(i).call();
        tempVal = this.state.web3.utils.fromWei(tempVal, 'ether');
        poolDeposit.push(tempVal);
      }

      let poolIncome = [];
      for(let i=0;i<3;i++){
        let tempVal = await contract.methods.user_bonus_pool(accounts,i).call();
        tempVal = this.state.web3.utils.fromWei(tempVal, 'ether');
        poolIncome.push(tempVal);
      }

      let TotalBonus = await contract.methods.TotalBonus(accounts).call();
      TotalBonus = this.state.web3.utils.fromWei(TotalBonus, 'ether');
      let totalWithdrawn = await contract.methods.totalWithdrawn().call();
      totalWithdrawn = this.state.web3.utils.fromWei(totalWithdrawn, 'ether');
      let totalDeposits = await contract.methods.totalInvested().call();
      totalDeposits = this.state.web3.utils.fromWei(totalDeposits, 'ether');

      let referral_count = [];
      for(let i=0;i<6;i++){
        let tempVal = await contract.methods.referral_count(accounts,i).call();
        referral_count.push(tempVal);
      }      

      this.setState({ 
        userInfo,
        poolDeposit,
        poolIncome,
        TotalBonus,
        totalWithdrawn,
        totalDeposits,
        referral_count,
        networkMain:true,
        YourAddress:YourAddress,
        YourAddressfull:YourAddressfull,
      });

     
    }

    render() {
      return (
        <Frame withHeader={true} withFooter={true} showEnter={true}>

          <Joinnow props={this.props} state={this.state} fetchData={this.fetchData}></Joinnow>
          
          {/* Banner */}
          <div className="banner_section middle_text">
            <div className="container">
                <div className="banner_text">
                  <div className="text-center pb_30">
                    <h1>FUTURE OF <span>LOTTERY</span></h1>
                    <p>100% decentralized network and 100% distribution </p>
                    <p>We have designed a networker lobbying system over Binance Chain.</p>
                  </div>
                
                </div>
              </div>  
            </div>
           {/* Banner */}


          <section className="pt_40 pb_40 dark_bg" id="about">
            <div className="container">
           

            <div className="row">
              <div className="col-md-9 col-sm-8 middle_text">
                <div className="all_heading">
                  <h2> ABOUT <span>US</span></h2>
                </div>
                <p className="text_line_height">Lionspoint is a blockchain technology provider integrated with multiple solutions on cryptocurrency. Lottery system built on Blockchain provides huge potential in future for a transparent lottery market.</p>
               
                </div>
              <div className="col-md-3 col-sm-4">
                <img className="img-fluid mobile_none" src="img/about.jpg" alt="" />
              </div>
            </div>
          
            </div>
          </section>

          <section className="pb_40 pt_40">
            <div className="container">
              <div className="text-center">
              
                <p className="text_line_height">Lionspoint is launching a decentralized lottery platform based on Binance Chain. We are focused to build an ecosystem where people can be a part of fair and safe lottery program. </p>
              </div>
             
            </div>
          </section>

          <section className="pt_40 pb_40 dark_bg ">
            <div className="container">
            
            <div className="row">
              <div className="col-md-3 col-sm-4">
                <img className="img-fluid mobile_none" src="img/image2.jpg" alt="" />
              </div>
              <div className="col-md-9 col-sm-8 middle_text">
                <div className="all_heading">
                  <h2>What is Binance  <span>Smart Chain?</span></h2>
                </div>
                <p className="text_line_height">The Binance Smart Chain is defined as a blockchain network that runs the smart contract-based applications or the dApps, but here the only difference is that BSC runs alongside the native Binance Chain. This gives the network users more convenience with the capacity of handling multiple high-speed transactions along with the smart contract functionality of Binance all at one place.</p>
              </div>
            </div>
            </div>
          </section>

          <Pools></Pools>

         {/* Features */}
        <section className="form_bg" id="Features">
          <div className="container">
            <div className="box_shadow">
            <div className="row">
              <div className="col-md-5 col-sm-5">
                <img className="img-fluid" src="img/friends.jpg" alt="" />
              </div>
              <div className="col-md-7 col-sm-7 middle_text">
                <div className="box_padding">
                  <div className="ext_heading">
                    <h2>WHAT IS LIONSPOINT.ORG?</h2>
                  </div>
                  <p className="mb-0">Lionspoint is powered by Blockchain, delivers the safety and transparency into the lottery program. Built on Binance Chain, Lionspoint is a 100% decentralized network offering 100% distribution. </p>
                </div>
              </div>
            </div>
        
            </div>
            <div className="box_shadow">
            <div className="row">
              
              <div className="col-md-7 col-sm-7 middle_text">
                <div className="box_padding">
                  <div className="ext_heading">
                    <h2>What is BNB coin?</h2>
                  </div>
                  <p className="mb-0">
                  Binance Coin is the cryptocurrency issued by the Binance exchange and trades with the BNB symbol.
                  BNB was initially based on the Ethereum network but is now the native currency of Binance's own blockchain, the Binance chain.
                  Every quarter, Binance uses one-fifth of its profits to repurchase and permanently destroy, or "burn," Binance coins held in its treasury.
                  Binance was created as a utility token for discounted trading fees in 2017, but its uses have expanded to numerous applications, including payments for transaction fees (on the Binance Chain), travel bookings, entertainment, online services, and financial services.
                  At the time of writing, Binance had a market cap of $88,276,993,118 and ranks behind only Bitcoin and Ethereum in terms of size.
                  </p>
                </div>
              </div>
              <div className="col-md-5 col-sm-5">
                <img className="img-fluid" src="img/bnb-coin.jpg" alt="" />
              </div>
            </div>
        
            </div>
           
          </div>
        </section>
         {/* Features */}


        {/* Faq */}
        <section className="pt_40 pb_40 light_bg" id="faq">
          <div className="container">
            <div className="all_heading text-center">
              <h2>All your  <span>queries answered!</span></h2>
            </div>

            <div className="faq_inner">
              <div className="accordion_border">
                <div className="accordion_head">
                  <span className="plusminus"><i className="fa fa-angle-down"></i></span>
                  What are the things best done on the Binance platform?
                </div>
                <div className="accordion_body" style={{display: "block"}}>
                  <p>This crypto sending and receiving portal is the right place for the exchange of coins and gifts (decentralized donations). The platform even allows you to mint tokens; issue new tokens, and helps you create tokens or token pairs as per the terms & conditions. Binance is also the best place for buying and selling cryptocurrencies and make proper use of the trade pairs created on the platform.</p>
                </div>
              </div>
              <div className="accordion_border">
                <div className="accordion_head">
                  <span className="plusminus"><i className="fa fa-angle-down"></i></span>
                  How do I create a digital wallet for BSC?
                </div>
                <div className="accordion_body">
                  <p>The digital wallet configuration on BSC is simple as the word “simple” sounds. Being a platform user, all you have to do is initiate the setup process on Trust Wallet to be used on Binance Smart Chain. This simple setup can be done using a mobile app or even the popular browsers used on the computers as well. When your wallet is configured for BSC, Trust Wallet will be marked as active and ready to be used for deposits and withdrawals of cryptos.</p>
                </div>
              </div>
              <div className="accordion_border">
                <div className="accordion_head">
                  <span className="plusminus"><i className="fa fa-angle-down"></i></span>
                  How do I issue and exchange coins on BSC?
                </div>
                <div className="accordion_body">
                  <p>Issuance and exchange of coins on BSC are quite simple like the exchanges done between other cryptocurrencies. However, the only thing to keep a note of here is that BNB coins are best utilized both for making payments and trading. The coins issued on BSC come embossed with the BNB symbol and the coins run on the Ethereum blockchain. These coins can be exchanged with other digital currencies, but there is a catch, because of running on the Ethereum blockchain using ERC20 there will be a limiting cap of 200 million tokens.</p>
                </div>
              </div>
              <div className="accordion_border">
                <div className="accordion_head">
                  <span className="plusminus"><i className="fa fa-angle-down"></i></span>
                  How does BSC function?
                </div>
                <div className="accordion_body">
                  <p>BSC functions or works on the basis of the Proof-of-Stake (POS) consensus algorithm and the Proof-of-Staked Authority or PoSA consensus as well. The only difference here is that being a network participant you are allowed to stake and serve as validators. But, only on completion of the validation process of a block, the transaction fees will be released.</p>
                </div>
              </div>
              <div className="accordion_border">
                <div className="accordion_head">
                  <span className="plusminus"><i className="fa fa-angle-down"></i></span>
                  How do I receive BNB on BSC?
                </div>
                <div className="accordion_body">
                  <p>You can receive BNB on BSC either as BNB on BSC or receive BNB straight from a Trust wallet. However, you can even receive BNB on withdrawal of BSC credentials from Binance as well. But in the case of holding an active Trust wallet, the easiest way to receive BNB is by using the Trust wallet Smart Chain address. </p>
                </div>
              </div>
              <div className="accordion_border">
                <div className="accordion_head">
                  <span className="plusminus"><i className="fa fa-angle-down"></i></span>
                  How are BSC and Metamask bridged?
                </div>
                <div className="accordion_body">
                  <p>A Metamask is basically created on the Ethereum-backed websites which makes it more secure and reliable. But when it comes to bridging Metamask and BSC (Binance Smart Chain), only a browser extension will get the job done. The browser extension is downloadable from any software site or if you are a mobile user then it would be best to download the extensions from the app stores (Google Play & iOS) depending on the device you use. Once the extensions are installed the last thing needed to be completed is to create a wallet for fund transactions.</p>
                </div>
              </div>
            
           
            </div>
          </div>
        </section> 
         {/* Faq */}

        </Frame>
      );
    }
  }

  export default withRouter(Home);
  //export default Home;